/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { RefundResult, RefundStatus } from '@/models/refund'
import { RefundStatusResponse } from '@dena/hoop-proto-client/dist/supports_pb'

export function mapRefund(data: RefundStatusResponse.AsObject): RefundResult {
  return {
    password: data.password ? data.password : undefined,
    refundAmount: data.refundAmount,
    status: mapRefundStatus(data.status),
  }
}

function mapRefundStatus(status: number): RefundStatus {
  switch (status) {
    case 0:
      return RefundStatus.UNSPECIFIED
    case 1:
      return RefundStatus.FREE_USER
    case 2:
      return RefundStatus.ACCEPTING
    case 3:
      return RefundStatus.ACCEPTED
    case 4:
      return RefundStatus.CLOSED
    default:
      return RefundStatus.UNSPECIFIED
  }
}
