import { useCallback, useMemo, useState } from 'react'
import styles from './index.module.scss'
import { RefundAmount } from './refund-amount'
import { MainForm } from './mail-form'
import { ConfirmModal } from './confirm-modal'
import { RefundPassword } from './refund-password'
import { useHome } from '@/hooks/pages/index'
import { AcceptedNote } from './note/accepted'
import { AcceptingNote } from './note/accepting'

type Props = {
  isAccepted: boolean
  amount: number
  password?: string
}

export const Applicable = ({ isAccepted, amount, password }: Props) => {
  const [isOpenEmailConfirm, setIsOpenEmailConfirm] = useState<boolean>(false)
  const [email, setEmail] = useState<string>()
  const { sendEmail } = useHome()

  const handleSubmit = useCallback((email: string) => {
    setIsOpenEmailConfirm(true)
    setEmail(email)
  }, [])

  const handleSendEmail = useCallback(
    (email: string) => {
      setIsOpenEmailConfirm(false)
      sendEmail(email)
    },
    [sendEmail],
  )

  const contentElement = useMemo<JSX.Element>(() => {
    if (isAccepted) {
      return (
        <>
          <AcceptedNote />
          <div className={styles.accepted}>
            <div className={styles.notice}>返金受付済み</div>
            <div className={styles.text}>最大10日程度でco_info@well-net.jpから返金案内のメールが届きますので、ご確認をお願いいたします。</div>
            <RefundPassword password={password} />
            <RefundAmount amount={amount} />
          </div>
        </>
      )
    } else {
      return (
        <>
          <AcceptingNote />
          <RefundAmount amount={amount} />
          <div className={styles.notice}>返金のご案内をお送りするメールアドレスをご記入ください</div>
          <MainForm onSubmit={handleSubmit} />
        </>
      )
    }
  }, [amount, handleSubmit, isAccepted, password])

  return (
    <div className={styles.container}>
      {contentElement}
      <ConfirmModal isOpen={isOpenEmailConfirm} onOk={handleSendEmail} onCancel={() => setIsOpenEmailConfirm(false)} email={email} />
    </div>
  )
}
