import styles from './index.module.scss'

export const AcceptedNote = () => {
  return (
    <div className={styles.text}>
      <p>いつも「PICKFIVE」をご利用いただきまして、誠にありがとうございます。</p>
      <p>
        「PICKFIVE」はB.LEAGUE 2023-24シーズンをもって、サービスを終了いたします。
        <br />
        ご愛顧いただいたお客様には、今までご支援いただきましたこと心より感謝申し上げます。
      </p>
      <p>
        ■返金対応について
        <br />
        これまで「PICKFIVE」で販売された「有償NFTカード」をご購入いただいたお客様で、返金をご希望されるお客様に全額返金をさせていただきます。
        <br />
        「返金受付フォーム」よりお申し出いただいたお客様には、フォームに入力いただいたメールアドレス宛に順次、送金サービスのご案内メールをお送りしております。
      </p>
      <p>
        ■返金対象の有償NFTカード
        <br />
        これまでPICKFIVEのSTORE(ストア)で販売された3,000円、5,000円、10,000円の有償NFTカード。
      </p>
      <p>
        ■返金希望受付方法
        <br />
        以下の受付期間内に、「返金受付フォーム」よりお申し出ください。
        <br />
        返金対象者となる、有償NFTカードを購入したLINEアカウントでログインいただいたお客様には「返金受付フォーム」が表示されます。
        <br />
        返金対象以外のお客様には、「返金受付フォーム」は表示されません。
      </p>
      <p>
        ＜受付期間＞
        <br />
        2025/2/20(木) 23:59まで
      </p>

      <p>
        【注意事項】
        <br />
        ※払戻しはウェルネット株式会社の送金サービスにより行われます。
        <br />
        ※送金サービスのご案内メールは（co_info@well-net.jp）からご案内いたしますので、あらかじめドメイン名「@well-net.jp」の受信指定（許可）を行う等メール内の設定をご確認ください。
      </p>
      <p>
        ■今後のスケジュール
        <br />
        2024/11/30(土) 23:59以降 無償/有償NFTカードの削除
        <br />
        削除以降、無償/有償NFTカードの閲覧はできなくなります。
        <br />
        2025/2/28(金) 23:59以降 PICKFIVE公式サイト閉鎖
        <br />
        ※返金希望受付のサイト内からの申請は2025/2/20(木) 23:59までとなります。
      </p>
      <p>
        ■本件に関するお問合せ先
        <br />
        <a href="https://dena.com/jp/contact/service-personal/" target="_blank" rel="noreferrer">
          https://dena.com/jp/contact/service-personal/
        </a>
      </p>
    </div>
  )
}
