type Props = {
  isLogin: boolean
  isCreateUser: boolean
  isSetBirthday: boolean
  answeredEnquete: boolean
  hasWallet: boolean
  isMaintenance: boolean
}

export default function getGuardPath({ isLogin, isCreateUser, isSetBirthday, answeredEnquete, hasWallet, isMaintenance }: Props): string | undefined {
  if (isMaintenance) {
    return '/maintenance'
  }
  if (!isLogin) {
    return '/login'
  }
  if (!isCreateUser) {
    return '/settings/user/create'
  }
  if (!isSetBirthday) {
    return
  }
  if (!answeredEnquete) {
    return
  }
  if (!hasWallet) {
    return '/wallet'
  }
}
