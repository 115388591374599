import type { NextPage } from 'next'
import { GetServerSidePropsResult } from 'next'
import router from 'next/router'
import { HomeContents } from '@/components/home'
import { useEffect, useMemo } from 'react'
import { useLoading } from '@/hooks/use-loading'
import { useSiteData } from '@/hooks/use-site-data'
import { useHome } from '@/hooks/pages/index'
import { useErrorModal } from '@/hooks/use-error-modal'
import { LayoutDefault } from '@/components/layouts/default'
import getGuardPath from '@/guards/pages/index'
import { HoopMetadata } from '@/models/metadata'

type Props = {
  meta?: HoopMetadata
}

export async function getServerSideProps(): Promise<GetServerSidePropsResult<Props>> {
  return {
    props: {
      meta: {
        title: 'PICKFIVE（ピックファイブ）- あなたは、どの5人で戦う？',
        description: 'PICKFIVE（ピックファイブ）は、試合で活躍する川崎ブレイブサンダースの選手を予想して楽しむことのできるサービスです。',
      },
    },
  }
}

const Home: NextPage = () => {
  const { isLogin, isCreateUser, isSetBirthday, answeredEnquete, hasWallet, isMaintenance } = useSiteData()
  const { state, getPageData } = useHome()
  const { loadingFinish } = useLoading()
  const { open: openErrorModal } = useErrorModal()

  const homeContent = useMemo(() => {
    if (!state) {
      return
    }
    if (!state.result) {
      return
    }

    return <HomeContents refundData={state.result.refund} />
  }, [state])

  useEffect(() => {
    const guardPath = getGuardPath({ isLogin, isCreateUser, isSetBirthday, answeredEnquete, hasWallet, isMaintenance })
    if (guardPath) {
      router.push(guardPath)
      return
    }

    getPageData()
  }, [])

  useEffect(() => {
    if (!state || state.isLoading) {
      return
    }

    if (state.result) {
      loadingFinish()
    } else {
      openErrorModal({})
    }
  }, [loadingFinish, openErrorModal, state])

  return <LayoutDefault>{homeContent}</LayoutDefault>
}

export default Home
