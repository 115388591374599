import styles from './index.module.scss'

type Props = {
  password?: string
}

export const RefundPassword = ({ password }: Props) => {
  return (
    <div className={styles.container}>
      <div className={styles.text}>返金パスワード</div>
      <div className={styles.password}>{password}</div>
    </div>
  )
}
