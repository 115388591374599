import styles from './index.module.scss'
import { useMemo } from 'react'
import { NotApplicable } from './repayment/not-applicable'
import { Applicable } from './repayment/applicable'
import { RefundData } from '@/models/refund'

type Props = {
  refundData: RefundData
}

export const HomeContents = ({ refundData }: Props) => {
  const repaymentContent = useMemo<JSX.Element | undefined>(() => {
    if (refundData.isApplicable) {
      return <Applicable isAccepted={refundData.isAccepted} amount={refundData.amount} password={refundData.password} />
    } else {
      return <NotApplicable />
    }
  }, [refundData])

  return (
    <>
      <div className={styles.home}>
        <div className={styles.title}>サービス終了のお知らせ</div>
        <div className={styles.repayment}>{repaymentContent}</div>
      </div>
    </>
  )
}
