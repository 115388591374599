import { useState, useCallback } from 'react'
import * as Sentry from '@sentry/nextjs'
import { HoopState } from '@/models/hook'
import { getFirebaseLoginToken } from '@/lib/firebase/auth'
import { BaseHoopError, HoopErrorUnknown } from '@/lib/error'
import { useSiteData } from '@/hooks/use-site-data'
import refundStatus from '@/lib/grpc/refund-status'
import refundEmail from '@/lib/grpc/refund-email'
import { RefundData, RefundStatus } from '@/models/refund'
import { useRouter } from 'next/router'

// page data state
type GetPageDataState = HoopState<
  {
    refund: RefundData
  },
  HoopErrorUnknown
>

export function useHome() {
  const [state, setState] = useState<GetPageDataState>()
  const { firebaseUid, user } = useSiteData()
  const router = useRouter()

  const getPageData = useCallback(async () => {
    try {
      const token = await getFirebaseLoginToken()

      if (!token) {
        throw new HoopErrorUnknown('cannot get login token')
      }

      if (!firebaseUid) {
        throw new HoopErrorUnknown('cannot get login uid')
      }

      if (!user || !user.nickname || !user.userName) {
        throw new HoopErrorUnknown('not create user')
      }

      const data = await refundStatus(token)
      const isApplicable = data.status === RefundStatus.ACCEPTING || data.status === RefundStatus.ACCEPTED
      const isAccepted = data.status === RefundStatus.ACCEPTED

      const refund: RefundData = {
        isApplicable: isApplicable,
        isAccepted: isAccepted,
        amount: data.refundAmount,
        password: data.password,
      }

      setState({
        isLoading: false,
        result: {
          refund,
        },
        error: undefined,
      })
    } catch (error) {
      Sentry.captureException(error)
      setState({
        isLoading: false,
        result: undefined,
        error: error instanceof BaseHoopError ? error : new HoopErrorUnknown('faild home page data'),
      })
    }
  }, [firebaseUid, user])

  const sendEmail = useCallback(
    async (email: string) => {
      try {
        const token = await getFirebaseLoginToken()

        if (!token) {
          throw new HoopErrorUnknown('cannot get login token')
        }

        await refundEmail(email, token)
        router.reload()
      } catch (error) {
        console.log('send email error = ', error)
        error instanceof BaseHoopError ? error : new HoopErrorUnknown('faild home page data')
      }
    },
    [router],
  )

  return {
    state,
    getPageData,
    sendEmail,
  }
}
