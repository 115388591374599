import styles from './index.module.scss'
import LoadingIcon from '@/assets/icons/loading.svg'
import { useMemo } from 'react'
import classNames from 'classnames'
import { ButtonProps, LinkProps, ButtonType } from '@/models/button'
import Link from 'next/link'

type Props = ButtonProps | LinkProps

export const ButtonGold = (props: Props) => {
  const buttonIcon = useMemo<JSX.Element | undefined>(() => {
    if (props.type === ButtonType.LINK) return
    if (props.isLoading) return <LoadingIcon className={styles.loadingIcon} width={24} height={24} />
  }, [props])

  const buttonElement = useMemo<JSX.Element>(() => {
    switch (props.type) {
      case ButtonType.BUTTON:
        return (
          <button className={classNames(styles.button_gold, { [styles.loading]: props.isLoading })} disabled={!props.isActive} onClick={() => props.onClick()}>
            {props.children}
            {buttonIcon}
          </button>
        )
      case ButtonType.LINK:
        return (
          <div className={styles.button_gold}>
            <Link href={props.href}>
              <a>{props.children}</a>
            </Link>
          </div>
        )
    }
  }, [buttonIcon, props])

  return buttonElement
}
