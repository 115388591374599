export interface RefundResult {
  refundAmount: number
  password?: string
  status: RefundStatus
}

export interface RefundData {
  isApplicable: boolean // 返金対象者
  isAccepted: boolean // 返金受付済
  amount: number // 返金額
  password?: string // 返金パスワード
}

export const RefundStatus = {
  UNSPECIFIED: 'unspecified',
  FREE_USER: 'freeUser', // 有料購入なし
  ACCEPTING: 'accepting', // 有料購入あり
  ACCEPTED: 'accepted', // 返金受付済
  CLOSED: 'closed', // 返金終了
} as const
export type RefundStatus = typeof RefundStatus[keyof typeof RefundStatus]
