import styles from './index.module.scss'
import { useEffect, useRef } from 'react'
import { CSSTransition } from 'react-transition-group'
import ExclamationCircleIcon from '@/assets/icons/exclamation-circle.svg'
import { useScrollLock } from '@/hooks/use-scroll-lock'

type Props = {
  isOpen: boolean
  onOk: (email: string) => void
  onCancel: () => void
  email?: string
}

export const ConfirmModal = ({ isOpen, onOk, onCancel, email }: Props) => {
  const nodeRef = useRef(null)
  const scrollElement = useRef<HTMLDivElement>(null)
  const { scrollTarget, unScrollTarget } = useScrollLock()

  const handleClickOk = () => {
    if (!email) return
    onOk(email)
  }

  useEffect(() => {
    if (!scrollElement.current) {
      return
    }

    if (isOpen) {
      scrollTarget(scrollElement.current)
    } else {
      unScrollTarget(scrollElement.current)
    }
  }, [isOpen])

  return (
    <CSSTransition
      in={isOpen}
      timeout={{
        enter: 0,
        exit: 500,
      }}
      unmountOnExit
      classNames={{
        enterActive: styles.enterActive,
        enterDone: styles.enterDone,
        exitActive: styles.exitActive,
        exitDone: styles.exitDone,
      }}
      nodeRef={nodeRef}
    >
      <div className={styles.container} ref={nodeRef}>
        <div className={styles.contents} ref={scrollElement}>
          <div className={styles.icon}>
            <ExclamationCircleIcon width={54} height={54} />
          </div>
          <div className={styles.title}>{email}に返金のご案内をお送りします。</div>
          <button className={styles.button} onClick={handleClickOk}>
            OK
          </button>
          <button className={styles.buttonCancel} onClick={onCancel}>
            キャンセル
          </button>
        </div>
      </div>
    </CSSTransition>
  )
}
