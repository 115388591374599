import { grpc } from '@improbable-eng/grpc-web'
import { Supports } from '@dena/hoop-proto-client/dist/supports_pb_service'
import { RefundStatusRequest, RefundStatusResponse } from '@dena/hoop-proto-client/dist/supports_pb'
import { HoopErrorUnknown } from '@/lib/error'
import { RefundResult } from '@/models/refund'
import { mapRefund } from './parser/refund'

export default function refundStatus(token: string): Promise<RefundResult> {
  return new Promise((resolve, reject) => {
    const host = process.env.NEXT_PUBLIC_API_HOST
    if (!host) {
      reject(new HoopErrorUnknown('failed get api host'))
      return
    }

    const request = new RefundStatusRequest()
    const metadata: grpc.Metadata.ConstructorArg = {}
    metadata.authorization = `bearer ${token}`

    grpc.unary(Supports.RefundStatus, {
      request,
      host,
      metadata,
      onEnd: (response) => {
        const { status, message } = response
        if (status === grpc.Code.OK && message) {
          const refundStatusData = message.toObject() as RefundStatusResponse.AsObject
          console.info('RefundStatusRequest data = ', refundStatusData)
          const refund = mapRefund(refundStatusData)

          resolve(refund)
        } else {
          reject(new HoopErrorUnknown(`failed RefundStatusRequest: status = ${status}`))
        }
      },
    })
  })
}
