import { grpc } from '@improbable-eng/grpc-web'
import { Supports } from '@dena/hoop-proto-client/dist/supports_pb_service'
import { RefundEmailResuest } from '@dena/hoop-proto-client/dist/supports_pb'
import { HoopErrorUnknown } from '@/lib/error'

export default function refundEmail(email: string, token?: string): Promise<void> {
  return new Promise((resolve, reject) => {
    const host = process.env.NEXT_PUBLIC_API_HOST
    if (!host) {
      reject(new HoopErrorUnknown('failed get api host'))
      return
    }

    const request = new RefundEmailResuest()
    request.setEmail(email)

    const metadata: grpc.Metadata.ConstructorArg = {}

    if (token) {
      metadata.authorization = `bearer ${token}`
    }

    grpc.unary(Supports.RefundEmail, {
      request,
      host,
      metadata,
      onEnd: (response) => {
        const { status, message } = response
        if (status === grpc.Code.OK && message) {
          console.info('RefundEmailRequest data = ', message)
          resolve()
        } else {
          reject(new HoopErrorUnknown(`failed RefundEmailResuest: status = ${status}`))
        }
      },
    })
  })
}
