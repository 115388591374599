import { useCallback, useMemo, useState } from 'react'
import styles from './index.module.scss'
import * as yup from 'yup'
import { ButtonGold } from '@/components/commons/button-gold'
import { ButtonType } from '@/models/button'

type Props = {
  onSubmit: (email: string) => void
}

export const MainForm = ({ onSubmit }: Props) => {
  const [email, setEmail] = useState<string>()
  const [emailErrorMessage, setEmailErrorMessage] = useState<string>()

  const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    setEmail(value)

    // MEMO: yupのemailメソッド使うと全角チェックしないので、正規表現でemail形式チェック
    const schemaEmail = yup
      .string()
      .required()
      .matches(/^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)
    if (!schemaEmail.isValidSync(value)) {
      const errorMessage = value ? 'メールアドレスの形式で入力してください' : 'メールアドレスを入力してください'
      setEmailErrorMessage(errorMessage)
    } else {
      setEmailErrorMessage(undefined)
    }
  }

  const handleSubmit = useCallback(() => {
    if (!email) return
    onSubmit(email)
  }, [email, onSubmit])

  const isActive = useMemo<boolean>(() => {
    return !emailErrorMessage && !!email
  }, [email, emailErrorMessage])

  return (
    <div className={styles.container}>
      <label className={styles.label} htmlFor="email">
        メールアドレス
        <input className={styles.input} type="text" value={email} name="email" onChange={handleChangeEmail} onBlur={handleChangeEmail} />
      </label>
      <div className={styles.error}>{emailErrorMessage}</div>
      <div className={styles.submit}>
        <ButtonGold type={ButtonType.BUTTON} isActive={isActive} onClick={handleSubmit}>
          この内容で送信
        </ButtonGold>
      </div>
    </div>
  )
}
