import styles from './index.module.scss'

type Props = {
  amount: number
}

export const RefundAmount = ({ amount }: Props) => {
  return (
    <div className={styles.container}>
      <div className={styles.text}>返金予定額</div>
      <div className={styles.amount}>{amount.toLocaleString()}円</div>
    </div>
  )
}
